import React from "react"
import { ThemeProvider } from './src/context/ThemeContext';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
    --dark100: #000000;
    --dark90: rgba(0,0,0,0.9);
    --dark80: rgba(0,0,0,0.8);
    --dark70: rgba(0,0,0,0.7);
    --dark60: rgba(0,0,0,0.6);
    --dark20: rgba(0,0,0,0.2);
    --dark10: rgba(0,0,0,0.1);
    --light100: #FFFFFF;
    --light90: rgba(255,255,255,0.9);
    --light80: rgba(255,255,255,0.8);
    --light70: rgba(255,255,255,0.7);
    --light60: rgba(255,255,255,0.6);
    --light50: rgba(255,255,255,0.5);
    --light20: rgba(255,255,255,0.2);
    --light10: rgba(255,255,255,0.1);
    --hotpink: #f95295;
  }
  body, * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  b, strong {
    font-weight: 700;
  }
  a {
    font-weight: 400;
  }
`;

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>
    <GlobalStyle />
    {element}
  </ThemeProvider>
)